<template>
	<v-container class="pa-0" fluid v-if="mounted">
		<v-row no-gutters :style="stickyTop" class="white">
			<v-col cols="12">
				<v-container class="pa-0">
					<v-toolbar flat dense>
						<v-toolbar-title> Product catalogus</v-toolbar-title>
						<template v-slot:extension>
							<!-- CATEGORIES -->
							<CatalogTreeview />
							<v-spacer />
							<!-- BRANDS -->
							<v-spacer />
							<v-spacer />
							<v-autocomplete
								solo
								flat
								dense
								v-model="currentFilter"
								:items="brandNames"
								return-object
								hide-details
								menu-props="bottom, offset-y"
								append-icon="mdi-filter-outline"
								clearable
								placeholder="filter op merk"
							/>
							<v-spacer />
							<v-spacer />
							<!-- SEARCH -->
							<v-spacer />
							<v-text-field
								placeholder="zoek op naam"
								solo
								flat
								dense
								v-model="searchValue"
								append-icon="mdi-magnify"
								hide-details
								clearable
								@keyup.native.enter="onSearch"
								@click:append="onSearch"
								@click:clear="onClear"
							></v-text-field>
						</template>
					</v-toolbar>
				</v-container>
			</v-col>
			<!-- DIVIDER -->
			<v-col cols="12">
				<v-progress-linear
					v-if="loading"
					active
					indeterminate
					rounded
				></v-progress-linear>
				<v-divider v-else class="accent" style="padding-bottom: 2px" />
			</v-col>
		</v-row>
		<!-- CATALOG ITEMS -->
		<v-container>
			<v-row no-gutters justify="space-around">
				<v-col
					cols="auto"
					v-for="(RowObject, index) of itemList"
					:key="index"
				>
					<CatalogItemCard :rowObject="RowObject" />
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	import { mapGetters } from "vuex";
	import CatalogTreeview from "../components/CatalogTreeview.vue";
	import CatalogItemCard from "../components/CatalogItemCard.vue";

	export default {
		name: "ProductCatalog",
		components: {
			CatalogTreeview,
			CatalogItemCard,
		},
		data() {
			return {
				searchValue: "",
				mounted: false,
			};
		},
		mounted() {
			this.$store.dispatch("catalogItem/fetchBrands");
			this.$store.dispatch("catalog/fetchCatalogList").then(() => {
				this.mounted = true;
			});
		},
		computed: {
			...mapGetters({
				itemList: "catalog/itemList",
				brandNames: "catalogItem/brandNames",
				loading: "catalog/loading",
			}),
			currentFilter: {
				get() {
					return this.$store.getters["catalog/currentFilter"];
				},
				set(filter) {
					this.$store
						.dispatch("catalog/setCurrentFilter", filter)
						.then(() => {
							this.$store.dispatch("catalog/openQuery");
						});
				},
			},
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + 0 + "px",
					zIndex: 1,
				};
			},
		},
		methods: {
			onClear() {
				this.searchValue = "";
				this.onSearch();
			},
			onSearch() {
				this.$store
					.dispatch("catalog/setSearchText", this.searchValue)
					.then(() => {
						this.$store.dispatch("catalog/openQuery");
					});
			},
		},
	};
</script>
