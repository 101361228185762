<template>
	<v-menu
		max-height="75vh"
		offset-y
		bottom
		tile
		:close-on-content-click="closeOnContentClick"
		v-model="showMenu"
		z-index="1"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn text plain v-bind="attrs" v-on="on">
				{{
					currentCategoryName
						? currentCategoryName
						: "Kies een categorie"
				}}
				<v-icon
					right
					v-if="currentCategoryName"
					@click.stop="clearCategory"
					>mdi-close</v-icon
				>
				<v-icon right>mdi-chevron-down</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-subtitle>
				<v-text-field
					v-model="searchCategory"
					append-icon="mdi-magnify"
					clearable
				/>
			</v-card-subtitle>
			<v-treeview
				class="text-overline"
				hoverable
				dense
				activatable
				open-on-click
				return-object
				:items="categoryList"
				:item-key="categoryList.id"
				:search="searchCategory"
				@update:active="onSelectCategory"
			/>
		</v-card>
	</v-menu>
</template>
<script>
	import { mapGetters } from "vuex";
	export default {
		name: "CatalogTreeview",
		computed: {
			...mapGetters({
				currentCategoryName: "catalog/currentCategoryName",
				categoryList: "catalog/categoryList",
			}),
		},
		data() {
			return {
				showMenu: false,
				searchCategory: "",
				closeOnContentClick: false,
			};
		},
		methods: {
			onSelectCategory(pCategory) {
				if (!pCategory[0]) {
					return;
				}
				this.$store
					.dispatch("catalog/setCurrentCategory", pCategory[0])
					.then(() => {
						this.$store.dispatch("catalog/openQuery");
					})
					.finally(() => {
						this.$vuetify.goTo(0);
						this.showMenu = false;
					});
			},
			clearCategory() {
				this.$store.dispatch("catalog/setCurrentCategory", {}).then(() => {
					this.$store.dispatch("catalog/openQuery");
				});
			},
			closeMenu() {
				this.showMenu = false;
			},
		},
	};
</script>
