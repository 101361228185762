<template>
	<v-card flat v-on:click="getCatalogItemDetail" width="150">
		<v-card-title>
			<v-img
				class="secondary"
				style="border: 1px solid"
				position="center center"
				:src="
					rowObject.img_tn_url
						? rowObject.img_tn_url
						: '@/assets/imgnotfound.jpg'
				"
				width="100%"
				aspect-ratio="1"
				><v-sheet
					v-if="rowObject.is_new"
					style="
						position: absolute;
						opacity: 0.75;
						left: 0px;
						top: 0px;
					"
					class="info white--text text-caption"
					>Nieuw
				</v-sheet>
				<v-icon
					v-if="!rowObject.is_visible_website"
					style="position: absolute; right: 0px; top: 0px"
					color="warning"
					>mdi-cloud-off-outline
				</v-icon>
			</v-img>
		</v-card-title>
		<v-card-subtitle class="font-weight-medium">
			{{ rowObject.item_name }}
		</v-card-subtitle>
		<v-card-text class="primary--text font-weight-medium">
			<span
				:class="
					rowObject.is_promo && rowObject.promo_price
						? 'text-decoration-line-through'
						: ''
				"
				>{{ rowObject.end_user_price | toCurrency }}</span
			>
			<span
				v-if="rowObject.is_promo && rowObject.promo_price"
				class="px-2 red--text"
			>
				{{ rowObject.promo_price | toCurrency }}
			</span>
		</v-card-text>
	</v-card>
</template>
<script>
	export default {
		name: "CatalogItemCard",
		props: {
			rowObject: Object,
		},
		methods: {
			getCatalogItemDetail() {
				this.$router.push({
					name: "CatalogItem",
					params: { id: this.rowObject.item_id },
				});
			},
		},
	};
</script>
